import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { handleProducts } from '../utils/handleProducts';
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs';

function Champagne() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('bubbles');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "champagne" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
              }
            }
          }
        }
      }
    `)
  );


  return (
    <React.Fragment>
      <SEO
        title={'Champagne'}
        description={
          'Find den helt rigtige champagne. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#d4c52d" title="Champagne">
        <Products type="champagne" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Champagne til enhver smag',
            text:
              'Hos vinkammeret stræber vi efter at kunne præsentere dig for et hav af forskellige slags champagner, så du er sikret at finde en, som falder i din smag. Når du har fundet den slags champagne du ønsker at købe, så bliver du sendt videre til forhandleren, hvor du kan købe den. '
          }}
          secondColumn={{
            title: 'Forskellige slags champagne',
            text:
              'Der findes mange forskellige slags champagner, som hver især kan noget helt specielt. Du kan blandt andet købe slags som Blanc de blanc, Blanc de noirs, Rosé champagne, non vintage og mange flere.'
          }}
          thirdColumn={{
            title: 'Champagne fra Champagne regionen',
            text:
              'Champagnen kommer fra den franske region Champagne, i det nordøstlige Frankrig. Champagne er et beskyttet navn, som kun må benyttes om mousserende vin fra champagne distriktet.'
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'Champagne er en mousserende vin, som er vin med kulsyre. For at producere champagne, tilsætter man gær til druesaften, som omdanner druesaftens sukker til alkohol, og udskiller også kuldioxid. For at denne vin bliver til champagne, udsætter man vinen for endnu en gæring. Dette gør man ved at hælde vinen på flaske eller tank, og så tilsætte gær og sukker, hvorefter man lukker tanken eller flasken helt til, så man sikrer at kulsyren bliver i vinen. \n\nBoblerne i champagnen skal være små og klare, samt kunne holde sig i 10 minutter, efter champagnen er hældt op i glasset. \n\nChampagnen laves af en blanding af 3 druesorter, den hvide chardonnay og de sorte Pinot noir og Pinot Meunier. Grunden til at champagnen har en lys farve, på trods af brugen af sorte druer, er fordi man fjerner skallerne straks efter at druerne er blevet presset. \n\nBetegnelserne for om hvorvidt champagnen er tør eller sød er som følgende: \n\nBrut Natural: Meget tør\nBrut: Tør\nSec: Halvtør\nDemi Sec: Halvsød\nDemi Doux: Sød\nDoux: Meget sød\n\nDe mest populære champagnemærker er Moët & Chandon og Dom Perignon. (Som i øvrigt er ejet af Möet & Chandon virksomheden)'
          }
        />
        <Breadcrumbs type={'champagne'} />
      </Layout>
    </React.Fragment>
  );
}

export default Champagne;
